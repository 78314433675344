import { List } from 'immutable';

import { SegmentActions } from '@peakon/records/metrics';

import createPagination from '../utils/createPagination';

const segmentActions = (
  state = List<SegmentActions>(),
  action: $TSFixMe,
): List<SegmentActions> => {
  switch (action.type) {
    case 'MANAGER_METRICS_ACTION_SEGMENTS_SUCCESS': {
      const { data: segments } = action.data;

      return List(segments.map(SegmentActions.createFromApi));
    }

    case 'MANAGER_METRICS_ACTION_SEGMENTS_APPEND_SUCCESS': {
      const { data: segments } = action.data;

      return state.concat(List(segments.map(SegmentActions.createFromApi)));
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default createPagination(segmentActions, {
  startTypes: ['MANAGER_METRICS_ACTION_SEGMENTS'],
  appendTypes: ['MANAGER_METRICS_ACTION_SEGMENTS_APPEND'],
  removeTypes: [],
  resetTypes: [],
});
